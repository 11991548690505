import Leftsidebar from "./Sidebar/Sidebar.js";
import InfiniteScroll from "react-infinite-scroll-component";
import React, { useState, useEffect, useContext } from "react";
import PeopleItem from "./PeopleItem.js";
import { backend_url } from "../config.js";
import { AuthContext } from "./Auth/AuthProvider.js";

export default function People() {
  const [items, setItems] = useState([]);
  const { user, loading, accessToken } = useContext(AuthContext);
  const [page, setPage] = useState(0);
  useEffect(() => {
    if (!loading) {
      fetch(backend_url + "/api/getusers?page=" + page, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer: " + accessToken,
        },
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          // Data structure = posts collection from DB
          setPage(page + 1);
          setItems([...data]);
        })
        .catch((err) => console.error(err));
    }
  }, [loading]);

  function fetchData() {
    fetch(backend_url + "/api/getusers?page=" + page, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer: " + accessToken,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        // Data structure = posts collection from DB
        setPage(page + 1);
        setItems((items) => [...items, ...data]);
      })
      .catch((err) => console.error(err));
  }

  function refresh() {}

  return (
    <div style={{ display: "flex", flexWrap: "wrap", flexDirection: "row" }}>
      <Leftsidebar />
      <InfiniteScroll
        dataLength={items.length}
        next={fetchData}
        hasMore={true}
        loader={<h4>Loading...</h4>}
        endMessage={
          <p style={{ textAlign: "center" }}>
            <b>That's all!</b>
          </p>
        }
        // below props only if you need pull down functionality
        // refreshFunction={refresh}
        // pullDownToRefresh
        // pullDownToRefreshThreshold={50}
        // pullDownToRefreshContent={
        //     <h3 style={{ textAlign: 'center' }}>&#8595; Pull down to refresh</h3>
        // }
        // releaseToRefreshContent={
        //     <h3 style={{ textAlign: 'center' }}>&#8593; Release to refresh</h3>
        // }
      >
        <div className="w-full h-full mt-1 sm:mt-6 flex flex-col space-y-4">
          {items?.map((item) => (
            <PeopleItem key={item._id} person={item} />
          ))}
        </div>
      </InfiniteScroll>
    </div>
  );
}
