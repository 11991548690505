import React, { useState } from "react";
import Leftsidebar from "./Sidebar/Sidebar.js";
import PostsContainer from "./Posts/PostsContainer.js";
import ContactUs from "../components/ContactUs/ContactUs.js";
const Home = () => {
  const [category, setCategory] = useState("All");

  // Can Conditional render based upon other menu items - Post, Support, ETC
  const renderContent = () => {
    switch (category) {
      case "ContactUs":
        return <ContactUs />;

      default:
        return <PostsContainer category={category} className="min-w-0" />;
    }
  };

  return (
    <div className="flex flex-wrap flex-row w-full h-full">
      <div className="flex flex-row w-full">
        <Leftsidebar setCategory={setCategory} />

        {renderContent()}
      </div>
    </div>
  );
};

export default Home;
