import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Register from "./components/Auth/Register";
import Login from "./components/Auth/Login";
import Home from "./components/Home";
import Replies from "./components/Posts/Replies.js";
import CreatePost from "./components/Posts/CreatePost";
import TestComponent from "./components/TestComponent";
import Logout from "./components/Auth/Logout";
import People from "./components/People";
import Landing from "./components/Landing";
import { useContext } from "react";
import { AuthContext } from "./components/Auth/AuthProvider.js";
import UserProfile from "./components/Users/UserProfile";
import UserSettings from "./components/Users/UserSettings.js";
import Nav from "./components/Nav/Nav.js";

const App = () => {
  const { user, loading } = useContext(AuthContext);

  function ProtectedRoutes({ children }) {
    if (loading) {
      return <div>Loading...</div>; // Or a spinner/loader
    }

    if (user) {
      return <>{children}</>;
    } else {
      return (
        <Navigate
          to="/login"
          state={{
            from: location.pathname,
          }}
          replace
        />
      );
    }
  }

  function LandingPageRedirect({ children }) {
    if (loading) {
      return <div>Loading...</div>; // Or a spinner/loader
    }
    if (user) {
      return <>{children}</>;
    } else {
      return (
        <Navigate
          to="/landing"
          state={{
            from: location.pathname,
          }}
          replace
        />
      );
    }
  }

  return (
    <div className="w-full h-full">
      <BrowserRouter>
        <Nav />

        <Routes>
          <Route
            path="/"
            element={
              <LandingPageRedirect>
                <Home />
              </LandingPageRedirect>
            }
          />
          {/* <Route path='/landing' element={
                            <LandingPageRedirect>
                                <Home />
                            </LandingPageRedirect>
                        }
                    /> */}

          <Route
            path="/post"
            element={
              <ProtectedRoutes>
                <CreatePost question={false} />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/ask"
            element={
              <ProtectedRoutes>
                <CreatePost question={true} />
              </ProtectedRoutes>
            }
          />
          <Route path="/people" element={<People />} />
          <Route path="/profile/:user" element={<UserProfile />} />
          <Route path="/settings/:user" element={<UserSettings />} />

          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/post/:postId" element={<Replies />} />
          <Route path="/landing" element={<Landing />} />
          <Route path="/test" element={<TestComponent />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
