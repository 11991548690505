import { useEffect, useContext, useState } from 'react'
// import { addComment, likePost, savePost } from '../../actions/postAction';
import { commentIcon, emojiIcon, likeIconOutline, moreIcons, saveIconFill, saveIconOutline, shareIcon, likeFill} from '../SvgIcons.jsx'
import { Picker } from 'emoji-mart'
import ScrollToBottom from 'react-scroll-to-bottom';
import moment from 'moment';
import {backend_url} from "../../config.js";
import { Link, useNavigate } from "react-router-dom";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Tooltip from '@mui/material/Tooltip';
import TopAnswer from "./TopAnswer.js";
import { AuthContext } from "../Auth/AuthProvider.js";
import UserDetailsComponent from "../Users/UserDetailsComponent.js";
import { Button, message, Popconfirm } from 'antd';
import PostBottom from "./PostBottom.js";

const AnswerItem = ({ post, postCreator, add_reply, onDelete}) => {
    const creatorId = post?.uid
    const content = post?.content
    const title = post?.title

    const navigate = useNavigate();
    const { user, loading, accessToken } = useContext(AuthContext);
    const [isPostCreator, setIsPostCreator] = useState(false);

    useEffect(() => {
        setIsPostCreator(creatorId == user?.uid)
    }, [user]);

    return (
        <div className="flex flex-col border rounded bg-white relative border-zinc-300	drop-shadow	">
            <UserDetailsComponent postCreator={postCreator}/>

            {/* Content */}
            <div className="flex flex-col relative">
                <div className="font-sans text-xl pb-1 font-bold px-1"> {title} </div>
                <div className="whitespace-pre-wrap px-1 break-all">{content}</div>
            </div>



            <PostBottom post={post} isPostCreator={isPostCreator} commentIconBool={false} onDelete={onDelete} reply_page={true}/>

                            {/* num commments  */}
                {/* <span onClick={() => setOpenAnswerModal(true)} className="font-semibold text-sm cursor-pointer">{post?.replies == undefined ? 0 : post.replies.length} Comments </span>

                <Modal
                    open={openAnswerModal}
                    onCancel={() => setOpenAnswerModal(false)}
                    footer={null}
                >
                    <div>
                        <h2>Comments</h2>
                    </div>
                </Modal> */}
                {/* time */}

        </div >
    )
}

export default AnswerItem