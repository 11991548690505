import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import Leftsidebar from "../Sidebar/Sidebar.js";
import InfiniteScroll from "react-infinite-scroll-component";
import QuestionItem from "./QuestionItem.js";
import AnswerItem from "./AnswerItem.js";
import { db } from "../Auth/firebase.js";
import { collection, addDoc, getDoc, Timestamp } from "firebase/firestore";
import { backend_url } from "../../config.js";
import { AuthContext } from "../Auth/AuthProvider.js";

const Replies = () => {
  const { user, userInfo, accessToken } = useContext(AuthContext);

  const [reply, setReply] = useState("");
  const [answersAndCreators, setAnswersAndCreators] = useState([]);
  const [postCreator, setPostCreator] = useState(null);
  const [post, setPost] = useState(null);
  const { postId } = useParams();
  const [hasMorePosts, setHasMorePosts] = useState(false);

  useEffect(() => {
    fetch(backend_url + "/api/replies_page_from_id?post_id=" + postId, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setPostCreator(data.postCreator);
        setPost(data.question);
        setAnswersAndCreators(data.replies);
        return data;
      })
      .catch((error) => {
        console.error("Error retrieving post or children", error);
      });
  }, [postId]);

  const handleSubmitReply = async (e) => {
    e.preventDefault();
    try {
      const newDoc = {
        content: reply,
        uid: user.uid,
        parent: postId,
        timestamp: Timestamp.now(),
      };
      const docRef = await addDoc(collection(db, "posts"), newDoc);
      newDoc.id = docRef.id;
      const new_doc_and_creator = {
        reply: newDoc,
        creator: userInfo,
      };

      fetch(
        backend_url +
          "/update/post/add_reply?post_id=" +
          postId +
          "&reply_id=" +
          docRef.id,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer: " + accessToken,
          },
        }
      );

      setAnswersAndCreators((answersAndCreators) => [
        new_doc_and_creator,
        ...answersAndCreators,
      ]);
      setReply("");
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };
  const handleDeletePost = (postId) => {
    setAnswersAndCreators((prevItems) =>
      prevItems.filter((item) => item.reply.id !== postId)
    );
  };

  return (
    <div className="flex flex-wrap w-full h-full">
      <div className="flex flex-row w-full">

      <Leftsidebar />

      <main className="flex flex-col grow p-6 bg-gray-50">
        <QuestionItem
          key={postId}
          post={post}
          postCreator={postCreator}
          reply_page={true}
        />

        <form onSubmit={handleSubmitReply} className="mb-6">
          <label
            htmlFor="reply"
            className="block text-lg font-medium text-gray-700 mb-2"
          >
            Answer Question
          </label>
          <textarea
            rows={3}
            value={reply}
            onChange={(e) => setReply(e.target.value)}
            type="text"
            name="reply"
            className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
            placeholder="Write here..."
          />
          <button
            type="submit"
            className="mt-4 px-4 py-2 bg-indigo-600 text-white rounded-lg shadow-md hover:bg-indigo-700 transition duration-200"
          >
            SEND
          </button>
        </form>

        <InfiniteScroll
          dataLength={answersAndCreators.length}
          // next={() => fetchReplies(post)}
          hasMore={hasMorePosts}
          loader={
            <h4 className="text-center text-gray-500 animate-pulse">
              Loading...
            </h4>
          }
          endMessage={
            <p className="text-center text-gray-600 mt-4">
              <b>That's all!</b>
            </p>
          }
          // refreshFunction={() => fetchReplies(post)}
          // pullDownToRefresh
          // pullDownToRefreshThreshold={50}
          // pullDownToRefreshContent={
          //     <h3 className="text-center text-gray-500">&#8595; Pull down to refresh</h3>
          // }
          // releaseToRefreshContent={
          //     <h3 className="text-center text-gray-500">&#8593; Release to refresh</h3>
          // }
          className="space-y-4"
        >
          <div className="space-y-4">
            {answersAndCreators?.map((item) => (
              <AnswerItem
                key={item.reply.id}
                post={item.reply}
                postCreator={item.creator}
                onDelete={handleDeletePost}
              />
            ))}
          </div>
        </InfiniteScroll>
      </main>
      </div>
    </div>
  );
};

export default Replies;
