import { Button, message, Popconfirm } from 'antd';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Tooltip from '@mui/material/Tooltip';
import { Link, useNavigate } from "react-router-dom";
import moment from 'moment';
import {backend_url} from "../../config.js";
import { commentIcon, likeIconOutline, likeFill} from "../SvgIcons.jsx";
import { useEffect, useContext, useState } from 'react'
import { AuthContext } from "../Auth/AuthProvider.js";

const PostBottom = ({ post, isPostCreator, answerers, commentIconBool=false, onDelete, reply_page = false}) => {
    const { user, loading, accessToken } = useContext(AuthContext);
    const navigate = useNavigate();

    const _id = post?.id
    const [allLikers, setAllLikers] = useState(0);
    const [liked, setLiked] = useState(false);

    useEffect(() => {
        setLiked(user && post?.likers?.includes(user.uid));
        setAllLikers(post?.likers?.length || 0);
    }, [user, post])


    const handleLike = async () => {
        try {
            if (!liked) {
                fetch(backend_url + "/update/post/add_like?post_id=" + _id, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer: " + accessToken,
                    }
                })
                setAllLikers(allLikers + 1);
            } else {
                fetch(backend_url + "/update/post/remove_like?post_id=" + _id, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer: " + accessToken,
                    }
                })
                setAllLikers(allLikers - 1);
            }
            setLiked(!liked);

        } catch(e) {
            console.error("Did not retrieve creator info", creatorId, e);
        }
    }


    const deletePost = async () => {
        try {
            await fetch(backend_url + "/delete/post?post_id=" + _id, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer: " + accessToken,
                }
            })
            console.log("Deleted Post id: ", _id)
            onDelete(_id)
        } catch(e) {
            console.error("Did not delete post", creatorId, e)
        }
    }
    const confirmDelete = (e) => {
        message.success('Deleted Post');
        deletePost();
    };

    return (
        <div className="flex flex-col px-1 space-y-1 border-b pb-2 mt-2">

            {/* icons container */}
            <div className="flex items-center justify-between py-2">
                <div className="flex space-x-4">
                    <Tooltip title="Like Post">
                        <button onClick={handleLike}>{liked ? likeFill : likeIconOutline}</button>
                    </Tooltip>
                    {commentIconBool &&
                    <Tooltip title="Go to Discussion">
                        <button onClick={() => {navigate('/post/' + _id)}}>{commentIcon}</button>
                    </Tooltip>
                    }
                </div>

                {isPostCreator && 
                    <Popconfirm
                    title="Delete Post"
                    description="Are you sure to delete this?"
                    onConfirm={confirmDelete}
                    okText="Yes"
                    cancelText="No"
                    >
                        <button>
                            <Tooltip title="Delete Post">
                                <DeleteOutlineIcon/>
                            </Tooltip>
                        </button>
                    </Popconfirm>
                }
            </div>

            {/* num likes  */}
            <span className="font-semibold text-sm">{allLikers} Likes </span>

            {/* Answerers  */}
            {!reply_page &&
                <Tooltip 
                    title={
                        <div style={{ whiteSpace: 'pre-line' }}>
                            <ol className="no-bullets">
                                {answerers?.map(answerer => 
                                <li key={answerer.link}>
                                    <Link to={`/profile/` + answerer.link}>
                                            {answerer.firstname} {answerer.lastname}
                                    </Link>
                                </li>
                                )}
                                <li>
                                    ...
                                </li>
                            </ol>
                        </div>
                    }
                    placement="bottom-start"
                >
                    <span onClick={() => {navigate('/post/' + _id)}} className="font-semibold text-sm cursor-pointer">{post?.replies == undefined ? 0 : post.replies.length} Answers </span>
                </Tooltip>
            }


            {/* time */}
            {/* {allComments.length > 0 ?

                <span onClick={() => setViewComment(!viewComment)} className="text-[13px] text-gray-500 cursor-pointer">
                    {viewComment ? "Hide Comments" :
                        allComments.length === 1 ?
                            `View ${allComments.length} Comment` :
                            `View All ${allComments.length} Comments`
                    }
                </span> :

                <span className="text-[13px] text-gray-500">No Comments Yet!</span>

            }
            <span className="text-xs text-gray-500 cursor-pointer">{moment(createdAt).fromNow()}</span>

            {viewComment &&
                <ScrollToBottom className="w-full h-52 overflow-y-auto py-1">
                    {allComments.map((c) => (
                        <div className="flex items-start mb-2 space-x-2" key={c._id}>
                            <img draggable="false" className="h-7 w-7 rounded-full object-cover mr-0.5" src={c.user.avatar} alt="avatar" />
                            <Link to={`/${c.user}`} className="text-sm font-semibold hover:underline">{c.user.username}</Link>
                            <p className="text-sm">{c.comment}</p>
                        </div>
                    ))}
                </ScrollToBottom>
            } */}

        </div>
    )
}

export default PostBottom;