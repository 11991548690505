import { useEffect, useContext, useState } from 'react'
import TopAnswer from "./TopAnswer.js";
import UserDetailsComponent from "../Users/UserDetailsComponent.js";
import { AuthContext } from "../Auth/AuthProvider.js";
import PostBottom from "./PostBottom.js";

const QuestionItem = ({ post, postCreator, answerers, onDelete, reply_page = false}) => {
    const content = post?.content
    const title = post?.title

    const { user } = useContext(AuthContext);

    const [isPostCreator, setIsPostCreator] = useState(false);

    useEffect(() => {
        setIsPostCreator(postCreator?.uid == user?.uid)
    }, [user]);



    return (
        <div className="flex flex-col border rounded bg-white relative border-zinc-300	drop-shadow">
            <UserDetailsComponent postCreator={postCreator}/>

            {/* Content */}
            <div className="flex flex-col relative">
                <div className="font-sans text-xl pb-1 font-bold px-1"> {title} </div>
                <div className="whitespace-pre-wrap px-1 break-all">{content}</div>
            </div>

            <PostBottom post={post} isPostCreator={isPostCreator} answerers={answerers} commentIconBool={true} onDelete={onDelete} reply_page={reply_page}/>

            {/* Reply */}
            {!reply_page && post?.replies && post.replies.length > 0 && (
                    <TopAnswer answer_id={post.replies[0]} />
            )}

        </div >
    )
}

export default QuestionItem