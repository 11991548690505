import React, { useState } from "react";
import { Link } from "react-router-dom";
import LoginComponent from "./LoginComponent.js";

const Login = () => {
  return (
    <>
      <LoginComponent />
      <p>
        Don't have an account? <Link to="/register">Create one</Link>
      </p>
    </>
  );
};
export default Login;

// import Cookies from "universal-cookie";
// const cookies = new Cookies();
// const handleSubmit = (e) => {
//     e.preventDefault();
//     fetch("http://localhost:4000/auth/login", {
//       method: "POST",
//       body: JSON.stringify({
//         email,
//         password,
//       }),
//       headers: {
//         "Content-Type": "application/json",
//       },
//     })
//     .then(res => res.json())
//     .then((data) => {
//         console.log(data)
//         if (data.success) {
//             cookies.set('token', JSON.stringify(data.token), { path: '/' });
//             navigate('/')

//         } else {
//             alert("Invalid credentials")
//         }
//     })
//     .catch((err) => console.error(err));
// };
